body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-check-input {
  top: 0.8rem;
  scale: 1.4;
  margin-right: 0.7rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root {
  height: 100%;
}

.btn-toggle::after {
  transform: rotate(-180deg);
}

.dropArea {
  border: dotted #555 3px;
  display: grid;
  place-items: center;
  background-color: rgb(231, 231, 231);
}

.dropText {
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.center-align:hover {
  text-decoration: underline;
  color: #0087ca;
  display: inline-block;
  position: relative;
}
.dropdown-menu {
  width: max-content;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
  pointer-events: none;
  color: gray;
}
.dropdown-item:hover {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  color: white !important;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;

  pointer-events: none;
}
input[type='search']:focus {
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

.center-div {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.progress-bar-container {
  margin: auto;
}

.progressbar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(0 201 229);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.table-header {
  position: sticky;
  z-index: 1;
  background: white;
  top: -1px !important;
}

.center-div {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-ellipse {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.custom-width {
  width: 1000px !important;
}

.attachment {
  max-width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

div#offcanvasprint {
  z-index: 9999 !important;
}

/* table resize */

table,
.divTable {
  width: fit-content;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
}

th,
.th,
td,
.td {
  box-shadow: inset 0 0 0 1px lightgray;
  padding: 0.25rem;
}

th,
.th {
  position: relative;
  font-weight: bold;
  height: 30px;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background: lightblue;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 1;
}

.resizer.isResizing {
  background: rgba(0, 201, 229);
  opacity: 1;
}

.table .resize-end {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  height: 42px;
  -webkit-box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.07);
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.07);
  position: sticky !important;
  left: 0 !important;
  right: 0px;
  float: right;
}
.table .resizable-row {
  transition: height 0.1s ease;
  position: relative;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
}

.table .resize-handle {
  width: 100%;
  height: 2px;
  -webkit-box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.07);
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.07);
  background: rgb(201, 234, 239);
  cursor: row-resize;
  position: absolute;
  bottom: 0;
  left: 0;
}

.css-olqui2-singleValue {
  color: #2d3748 !important;
}

.table-scroll {
  overflow: auto;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 10px;
  overflow: hidden;
  opacity: 1;
}

.custom-file-input::-webkit-file-upload-button {
  display: none;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: 'Browse';
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Upload';
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-file-label::after {
  content: 'Upload template';
}

.overlap-text-dash {
  background-size: cover;
  position: relative;
  background-position: center;
  width: 32px;
  height: 32px;
  margin-left: -9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.btn-icon {
  color: #000 !important;
  border: none !important;
}

.th-dropdown-toggle::after {
  margin-left: 0px !important;
  padding-left: 0px !important;
  display: none;
}

.thead-btn {
  font-size: 14px;
  color: rgba(107, 122, 153, 0.76) !important;
  font-weight: 700;
}

.form-floating > label {
  padding: 0.75rem 0.75rem;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(2.5rem + calc(1px * 2)) !important;
  line-height: 0.25;
}

.form-floating > .form-control:disabled ~ label {
  color: #636363;
}

.form-check-input:checked {
  background-color: #fff;
  border-color: #0040e2;
}

.form-check-input:focus {
  border-color: #0040e2;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-width: 50% !important;
}
.offcanvas.min-width,
.offcanvas-xxl.min-width,
.offcanvas-xl.min-width,
.offcanvas-lg.min-width,
.offcanvas-md.min-width,
.offcanvas-sm.min-width {
  --bs-offcanvas-width: 400px !important;
}

.custom-dropdown-button {
  background-color: rgb(199, 199, 199) !important;
}

.custom-dropdown-item:hover {
  background-color: rgb(0 201 229) !important;
}
.profile_logo {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background: rgba(0, 201, 229);
  background-size: cover;
  background-position: center;
}
.profileImageStyle {
  width: '150px';
  height: '150px';
  border-radius: '50%';
  object-fit: 'cover';
}
input[type='color'] {
  background-color: #fff;
  padding: 2px;
  width: 200px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 3px;
  margin: 5px;
}

.notification-dropdown::after {
  display: none;
}

.notification-profile-image {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  padding: 5px;
}

.notification-dropdown-items > li:hover {
  background: #dbdbdb;
  cursor: pointer;
}

.notification-dropdown-items .sub-content {
  font-size: 12px;
  color: gray;
  font-weight: 500;
}

.notification-dropdown-items {
  height: auto;
  max-height: 450px;
  width: 25vw;
  overflow: auto;
}

/* Notification CSS */

.notification-read-unread {
  /* left: 95% !important; */
  margin-top: 10px;
}

.notification-menu {
  height: auto;
  /* left: -400px !important; */
  max-height: 50vh;
  width: 30rem !important;
  overflow-y: scroll;
}

.badgeSize {
  font-size: xx-small !important;
}

.table-group-border {
  border-left: 2px solid #f3f3f3;
}

.badge-count {
  padding: 4px 7px;
  font-size: 10px;
  font-weight: 500;
  text-align: center !important;
  left: 23px;
  top: 5px;
}

.notification-icon::after {
  display: none !important;
}

.notification-time {
  font-size: 10px !important;
  padding-right: 0.6rem !important;
  float: right;
}

.message-content img {
  max-width: 100%;
  height: auto;
}

.preview-background {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

#custom-dropdown-basic-button::after {
  top: 17px;
  position: absolute;
}

.files-list {
  max-height: 200px;
  overflow: auto;
}

.uploaded-file-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div:where(.alert-icon) .info-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
  margin-top: 10px;
}

div:where(.alert-icon).alert-info.alert-icon-show .info-icon-content {
  animation: alert-animate-i-mark 0.5s;
}

div:where(.alert-icon).alert-info.alert-icon-show {
  animation: alert-animate-error-icon 0.5s;
}

div:where(.alert-icon).alert-info {
  border-color: #ffac65;
  color: #ffac65;
}

div:where(.alert-icon) {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 2.5em;
  border: 0.25em solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}

@keyframes alert-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes alert-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }

  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }

  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }

  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

.title-warp {
  max-width: 75%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .multiline-editor textarea:focus {
  position: absolute;
  z-index: 99999;
  height: 5rem !important;
  resize: both;
  width: auto;
}
.multiline-editor textarea {
  height: 1rem !important;
  transition: rows 0.3s;
  resize: none;
  overflow: hidden;
} */

.multiline-editor textarea:focus-within {
  position: absolute;
  z-index: 9999;
  width: fit-content;
  /* min-width: 500px; */
}
.multiline-editor textarea {
  height: 1rem;
  overflow: hidden;
  transition: rows 0.3s;
  resize: none;
}
.multiline-editor textarea:focus {
  height: 5rem;
  resize: both;
}
.multiline-editor textarea:not(:focus) {
  height: 1rem !important;
}

.table-group-border {
  position: absolute;
  left: -2px;
  top: 0;
  height: 100%;
  width: 2px;
  background: #f3f3f3;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  opacity: 1;
}

.table thead tr th {
  border-bottom-width: 0px;
}

.btn-outline-success.hover-none:hover {
  border-color: #059669 !important;
  background-color: transparent !important;
  color: #059669 !important;
}

.btn-outline-secondary.hover-none:hover {
  border-color: #00c9e5 !important;
  background-color: transparent !important;
  color: #00c9e5 !important;
}

.btn-outline-danger.hover-none:hover {
  border-color: #dc2626 !important;
  background-color: transparent !important;
  color: #dc2626 !important;
}

.btn-outline-primary.hover-none:hover {
  border-color: #0040e2 !important;
  background-color: transparent !important;
  color: #0040e2 !important;
}

.btn-outline-dark.hover-none:hover {
  border-color: #2d3748 !important;
  background-color: transparent !important;
  color: #2d3748 !important;
}

.thead-btn-active {
  font-size: 14px;
  color: #000 !important;
  font-weight: 700;
}

.nodata {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
  background-color: #00c9e5 !important;
}

.css-b62m3t-container {
  width: 100% !important;
}

.w-sm-75 {
  width: 100%;
}

@media (min-width: 769px) {
  .w-sm-75 {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .w-sm-75 {
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .w-sm-75 {
    width: 75%;
  }
}

.dashboard-layout {
  height: 100%;
}

.container-section {
  max-height: calc(75vh + 100px);
  overflow: auto;
}
.no-padding > * {
  padding-right: 0rem;
  padding-left: 0rem;
}

.row-padding-right-1 > * {
  padding-right: calc(0.5 * var(--bs-gutter-x));
}

.modal-body {
  max-height: 550px;
  overflow: auto;
}

.pdps-list {
  max-height: 70vh;
  overflow: auto;
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .pdps-list {
    max-height: 72vh;
    overflow: auto;
  }
}

@media (min-width: 2559px) {
  .pdps-list {
    max-height: 85vh;
    overflow: auto;
  }
}

.qbo-btn {
  background-color: #2ca01c !important;
}

.sort-dropdown-toggle::after {
  content: none !important;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .invoice-modal-dialog {
    max-width: 650px;
  }
}

.ait-conditions-grid-lines tbody td {
  box-shadow: inset 0px -1px 0px 0px lightgray;
}

.react-datepicker__current-month--hasYearDropdown {
  display: none;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select {
  display: inline-block;
  margin: 0 4px !important;
}
/* Css Only for Draggable pop up in project tracker*/

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.custom-modal-dialog {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 800px;
  padding: 1rem;
  position: relative;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e5e5;
}

.custom-modal-title {
  margin: 0;
}

.custom-modal-close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.custom-modal-body {
  padding: 1rem 0;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e5e5;
}

.custom-modal-button {
  background: #007bff;
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.custom-modal-button.custom-modal-submit {
  background: #28a745;
}


/* Css Only for Draggable pop up in project tracker*/

/* Container that covers the whole screen */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #636363;
  z-index: 999;
  pointer-events: none;
}

.testModal {
  width: 100%;
  max-width: 1140px;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: move;
  border-radius: 8px;
  pointer-events: auto;
}

.minimized {
  position: fixed;
  bottom: 0;
  left: 0;
  transform: none;
  width: 20%;
  height: auto !important;
  margin: 0 !important;
  border: none !important;
}

.add-scroll {
  max-height: 580px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Above 1440px */
@media (min-width: 1441px) {
  .testModal {
    max-width: 1280px;
    padding: 25px;
  }

  .add-scroll {
    max-height: 600px;
  }

  .minimized {
    width: 25%;
  }
}

/* Between 1440px and 1200px */
@media (max-width: 1440px) {
  .testModal {
    max-width: 1040px;
    padding: 15px;
  }

  .add-scroll {
    max-height: 380px;
  }

  .minimized {
    width: 40%;
  }
}

/* Between 1200px and 992px */
@media (max-width: 1200px) {
  .testModal {
    max-width: 900px;
    padding: 15px;
  }

  .add-scroll {
    max-height: 350px;
  }

  .minimized {
    width: 50%;
  }
}

/* Between 992px and 768px */
@media (max-width: 992px) {
  .testModal {
    max-width: 750px;
    padding: 10px;
  }

  .add-scroll {
    max-height: 300px;
  }

  .minimized {
    width: 60%;
  }
}

/* Between 768px and 576px */
@media (max-width: 768px) {
  .testModal {
    max-width: 600px;
    padding: 10px;
  }

  .add-scroll {
    max-height: 250px;
  }

  .minimized {
    width: 70%;
  }
}

/* Below 576px (Mobile) */
@media (max-width: 576px) {
  .testModal {
    max-width: 95%;
    padding: 10px;
  }

  .add-scroll {
    max-height: 200px;
  }

  .minimized {
    width: 90%;
  }
}

/* Below 400px (Small Mobile) */
@media (max-width: 400px) {
  .testModal {
    max-width: 100%;
    padding: 8px;
    border-radius: 0;
  }

  .add-scroll {
    max-height: 150px;
  }

  .minimized {
    width: 100%;
  }
}

